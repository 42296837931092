import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectControl: {
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function SimpleSelect(props) {
  const classes = useStyles();
  const entries = props.entries;
  const order = props.id === "morning" ? 1 : 2;
  let initialValue = null;
  if (entries && entries.length) {
    const entry = entries.find(
      (e) => e.date === props.date && e.order === order
    );
    if (entry && entry.title === "Anetka") {
      initialValue = 1;
    } else if (entry) {
      initialValue = 2;
    }
  }

  const [state, setState] = React.useState({
    [props.id]: null,
    driver: null,
  });

  React.useEffect(() => {
    if (initialValue) {
      setState({
        [props.id]: initialValue,
        driver: initialValue,
      });
      props.update(props.id, initialValue);
    }
  }, [initialValue, props]);

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
      driver: event.target.value,
    });
    props.update(name, event.target.value);
  };

  return (
    <div>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel htmlFor={props.id}>{props.text}</InputLabel>
        <Select
          className={classes.selectControl}
          native
          value={state.driver}
          onChange={handleChange}
          label={props.text}
          inputProps={{
            name: props.id,
            id: props.id,
          }}
        >
          <option aria-label="None" value="" />
          <option value={1}>Anetka</option>
          <option value={2}>Kasia</option>
        </Select>
      </FormControl>
    </div>
  );
}

import React from "react"
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction";
import Modal from 'react-modal';
import { Button } from '@material-ui/core';
import axios from 'axios';

import './App.css';
import SimpleSelect from "./simpleselect"

const customStyles = {
  content: {
    zIndex: '9999',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '200px'
  }
};


const App = () => {
  const [loaded, setLoaded] = React.useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [anetka, setAnetka] = React.useState(0);
  const [kasia, setKasia] = React.useState(0);
  const [entries, setEntries] = React.useState([]);
  const [currentDate, setCurrentDate] = React.useState('');
  // const [currentMorning, setCurrentMorning] = React.useState(null);
  // const [currentEvening, setCurrentEvening] = React.useState(null);
  var subtitle;

  function openModal() {
    setIsOpen(true);
  }

  const data = {
    date: currentDate
  };

  const handleDateClick = async (arg) => {
    const result = await axios({
      crossdomain: true,
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
      method: 'get',
      url: `/status`
    });
    if (!result.data.status) {
      // eslint-disable-next-line no-restricted-globals
      return location.href = '/login';
    }
    // const morning = entries.find(entry => entry.date === arg.dateStr && entry.order === 1);
    // const evening = entries.find(entry => entry.date === arg.dateStr && entry.order === 2);
    // setCurrentMorning(morning);
    // setCurrentEvening(evening);
    setCurrentDate(arg.dateStr);
    openModal();
  }

  const orderFn = (a, b) => {
    if (!a.order || !b.order) return 1;
    return a.order > b.order ? 1 : -1;
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  function update(name, value) {
    data[name] = parseInt(value);
  }

  async function load() {
    const result = await axios({
      crossdomain: true,
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
      method: 'get',
      url: `/entries`
    });
    const newEntries = [];
    for(let entry of result.data.entries) {
      const date = new Date(entry.date);
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const monthString = month.toString().length === 1 ? `0${month.toString()}` : month.toString();
      const dayString = day.toString().length === 1 ? `0${day.toString()}` : day.toString();
      const json = {
        title: entry.person === 1 ? 'Anetka' : 'Kasia',
        color: entry.person === 1 ? '' : 'red',
        date: `${date.getFullYear()}-${monthString}-${dayString}`,
        order: parseInt(entry.order)
      }
      newEntries.push(json);
    }
    setEntries(newEntries);
    setKasia(result.data.kasia);
    setAnetka(result.data.anetka);
  }

  async function save() {
    try {
      await axios({
        crossdomain: true,
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
        method: 'post',
        url: `/entries`,
        data: data,
      });
    } catch (err) {
      // eslint-disable-next-line no-restricted-globals
      return location.href = '/login';
    }
    closeModal();
    load();
  }

  if (!loaded){
    load();
    setLoaded(true);
  }

  const firstDay = 1;

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
      >

        <h2 ref={_subtitle => (subtitle = _subtitle)}>{currentDate}</h2>
        <form>
          <SimpleSelect date={currentDate} entries={entries} id={'morning'} update={update} text={'Rano'} />
          <SimpleSelect date={currentDate} entries={entries} id={'evening'} update={update} text={'Po południu'} />
          <div style={{ 'textAlign': 'center' }}>
            <Button variant="contained" color="primary" onClick={save}>Zapisz</Button>
          </div>
          <div style={{ 'textAlign': 'center', 'marginTop': 10 }}>
            <Button variant="contained" color="secondary" onClick={closeModal}>Anuluj</Button>
          </div>
        </form>
      </Modal>
      <FullCalendar firstDay={firstDay} locale="pl" eventOrder={orderFn} dateClick={handleDateClick} plugins={[dayGridPlugin, interactionPlugin]} events={entries} />
      <div>Anetka: {anetka}</div>
      <div>Kasia: {kasia}</div>
    </>
  )
}
export default App